var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-container',{ref:"container"},[_c('v-card',{staticClass:"fill-height",attrs:{"elevation":"2","shaped":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-uppercase pl-4 mr-auto"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex justify-end ml-auto pr-0 pb-2"},[_c('o-button',{attrs:{"busy":_vm.busy,"color":"warning","icon":"mdi-filter","type":"label-icon","label":"filter"},on:{"on-click":function($event){_vm.showFilter = true}}}),_c('o-button',{attrs:{"busy":_vm.busy,"color":"primary","icon":"mdi-sync","type":"label-icon","label":"Refresh"},on:{"on-click":function($event){return _vm.reset()}}}),_c('o-drawer',{attrs:{"auto-close":true,"actions":[
            {
              key: 'on-item-search',
              label: 'SEARCH',
              icon: 'mdi-text-box-search-outline',
              color: 'success',
            },
          ],"button-icon":"mdi-filter-plus-outline","title":`FILTERS`,"use-button":false,"width":"25vw","visible":_vm.showFilter},on:{"on-item-search":_vm.reset,"on-close":function($event){_vm.showFilter = false}}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Account","items":_vm.accounts},model:{value:(_vm.parameters.acc_id),callback:function ($$v) {_vm.$set(_vm.parameters, "acc_id", $$v)},expression:"parameters.acc_id"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"User","items":_vm.users},model:{value:(_vm.parameters.usr_id),callback:function ($$v) {_vm.$set(_vm.parameters, "usr_id", $$v)},expression:"parameters.usr_id"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Object Type","items":[
                  { id: '-', name: 'All' },
                  { id: 'account', name: 'Account' },
                  { id: 'advertiser', name: 'Advertiser' },
                  { id: 'agency', name: 'Agency' },
                  { id: 'audio_asset', name: 'Audio Asset' },
                  { id: 'campaign', name: 'Campaign' },
                  { id: 'companion_asset', name: 'Companion Asset' },
                  { id: 'creative', name: 'Creative' },
                  { id: 'creative_addon', name: 'Creative Addon' },
                  { id: 'creative_line_item', name: 'Creative Line Item' },
                  { id: 'creative_segment', name: 'Creative Segment' },
                  { id: 'custom_list', name: 'Custom List' },
                  { id: 'deal', name: 'Deal' },
                  { id: 'line_item', name: 'Line Item' },
                  { id: 'list_item', name: 'List Item' },
                  { id: 'manufacturer', name: 'Manufacturer' },
                  {
                    id: 'targeting_expression',
                    name: 'Targeting Expression',
                  },
                  { id: 'targeting_preset', name: 'Targeting Preset' },
                  { id: 'vendor', name: 'Vendor' },
                  { id: 'vendor_fee', name: 'Vendor Fee' },
                  { id: 'video_asset', name: 'Video Asset' },
                  { id: 'web_segment', name: 'Web Segment' },
                ]},model:{value:(_vm.parameters.obj_type),callback:function ($$v) {_vm.$set(_vm.parameters, "obj_type", $$v)},expression:"parameters.obj_type"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Object Id"},model:{value:(_vm.parameters.obj_id),callback:function ($$v) {_vm.$set(_vm.parameters, "obj_id", $$v)},expression:"parameters.obj_id"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Limit"},model:{value:(_vm.parameters.cnt),callback:function ($$v) {_vm.$set(_vm.parameters, "cnt", $$v)},expression:"parameters.cnt"}})],1)],1)],1)],1)]),_c('v-divider'),_c('o-drawer',{attrs:{"actions":[],"title":`ACTIVITY LOG DETAILS`,"visible":_vm.action !== '',"width":"75vw"},on:{"on-close":function($event){_vm.action = ''}}},[_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("ACCOUNT : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.account_name)+"("+_vm._s(_vm.model.account_id)+")")])]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("USER : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.user_full_name)+"("+_vm._s(_vm.model.user_id)+")")])]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("ACTION : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.action)+"D")])]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("OBJECT TYPE : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.object_type))])]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("OBJECT ID : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.object_id))])]),_c('div',[_c('span',{staticClass:"body-2"},[_vm._v("DATE : ")]),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(_vm._s(_vm.model.activity_date))])]),_c('o-table',{ref:"differences",attrs:{"type":"array","data":_vm.differences,"headers":[
          {
            text: 'NAME',
            align: 'start',
            sortable: false,
            value: 'name',
            type: 'text',
          },
          {
            text: 'OLD',
            align: 'start',
            sortable: false,
            value: 'old_value',
            type: 'text',
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'icon',
            type: 'slot',
          },
          {
            text: 'NEW',
            align: 'start',
            sortable: false,
            value: 'new_value',
            type: 'text',
          },
        ]}},[_c('template',{slot:"slot"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-arrow-right-bold ")])],1)],2)],1),_c('v-card-text',{staticClass:"text-primary"},[_c('o-table',{ref:"table",attrs:{"type":"rest","busy":_vm.busy,"has-paging":true,"height":_vm.getHeight(450),"fixed-header":true,"method":"POST","rest":{
          url: `/rest/intranet/execute?time=${new Date().getTime()}`,
          payload: {
            topic: '',
            name: 'check_activity_logs',
            params: {
              a01: _vm.parameters.acc_id,
              a02: _vm.parameters.usr_id,
              a03: _vm.parameters.obj_type,
              a04: _vm.parameters.obj_id,
              cnt: _vm.parameters.cnt,
              starts: _vm.parameters.starts,
            },
          },
        },"headers":[
          {
            text: 'ID',
            align: 'end',
            sortable: false,
            value: 'id',
            type: 'int',
          },
          {
            text: 'DATE',
            align: 'start',
            sortable: false,
            value: 'activity_date',
            type: 'date-time',
          },
          {
            text: 'ACCOUNT',
            align: 'start',
            sortable: false,
            value: 'account_name',
            type: 'slot',
          },
          {
            text: 'ACTION',
            align: 'start',
            sortable: false,
            value: 'action_name',
            type: 'slot',
          },
          {
            text: 'USER',
            align: 'start',
            sortable: false,
            value: 'user_full_name',
            type: 'slot',
          },
          {
            text: 'OBJECT TYPE',
            align: 'start',
            sortable: false,
            value: 'object_type',
            type: 'text',
            width: 100,
          },
          {
            text: 'OBJECT ID',
            align: 'end',
            sortable: false,
            value: 'object_id',
            type: 'text',
            width: 100,
          },
          {
            value: 'action',
            type: 'action',
            sortable: false,
            actions: [
              {
                key: 'on-item-edit',
                label: 'View',
                icon: 'mdi-view-headline',
                color: 'success',
              },
            ],
          },
        ]},on:{"set-busy":_vm.setBusy,"on-item-edit":_vm.edit,"on-refreshed":_vm.refreshed},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'account_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[(row.prop.item.account_id > 0)?[_vm._v(" "+_vm._s(row.prop.item.account_name)+" ("+_vm._s(row.prop.item.account_id)+") ")]:_vm._e()],2):_vm._e(),(row.prop.key === 'user_full_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[(row.prop.item.user_id > 0)?[_vm._v(" "+_vm._s(row.prop.item.user_full_name)+" ("+_vm._s(row.prop.item.user_id)+") ")]:_vm._e()],2):_vm._e(),(row.prop.key === 'action_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(row.prop.item.action_name.replace( /[-_\/[\]{}()*+?,\\^$|#\s]/g, " " ))+" ")]):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }