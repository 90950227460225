<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="filter"
            @on-click="showFilter = true"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="25vw"
            :visible="showFilter"
            @on-item-search="reset"
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pt-0 pb-0 ma-0">
                <o-combo-box
                  v-model="parameters.acc_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 ma-0">
                <o-combo-box
                  v-model="parameters.usr_id"
                  :busy="busy"
                  label="User"
                  :items="users"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 ma-0">
                <o-combo-box
                  v-model="parameters.obj_type"
                  :busy="busy"
                  label="Object Type"
                  :items="[
                    { id: '-', name: 'All' },
                    { id: 'account', name: 'Account' },
                    { id: 'advertiser', name: 'Advertiser' },
                    { id: 'agency', name: 'Agency' },
                    { id: 'audio_asset', name: 'Audio Asset' },
                    { id: 'campaign', name: 'Campaign' },
                    { id: 'companion_asset', name: 'Companion Asset' },
                    { id: 'creative', name: 'Creative' },
                    { id: 'creative_addon', name: 'Creative Addon' },
                    { id: 'creative_line_item', name: 'Creative Line Item' },
                    { id: 'creative_segment', name: 'Creative Segment' },
                    { id: 'custom_list', name: 'Custom List' },
                    { id: 'deal', name: 'Deal' },
                    { id: 'line_item', name: 'Line Item' },
                    { id: 'list_item', name: 'List Item' },
                    { id: 'manufacturer', name: 'Manufacturer' },
                    {
                      id: 'targeting_expression',
                      name: 'Targeting Expression',
                    },
                    { id: 'targeting_preset', name: 'Targeting Preset' },
                    { id: 'vendor', name: 'Vendor' },
                    { id: 'vendor_fee', name: 'Vendor Fee' },
                    { id: 'video_asset', name: 'Video Asset' },
                    { id: 'web_segment', name: 'Web Segment' },
                  ]"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 ma-0">
                <o-input
                  v-model="parameters.obj_id"
                  :busy="busy"
                  label="Object Id"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 ma-0">
                <o-input
                  v-model="parameters.cnt"
                  :busy="busy"
                  label="Limit"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider />

      <o-drawer
        :actions="[]"
        :title="`ACTIVITY LOG DETAILS`"
        :visible="action !== ''"
        width="75vw"
        @on-close="action = ''"
      >
        <div>
          <span class="body-2">ACCOUNT : </span
          ><span class="body-1 font-weight-bold"
            >{{ model.account_name }}({{ model.account_id }})</span
          >
        </div>
        <div>
          <span class="body-2">USER : </span
          ><span class="body-1 font-weight-bold"
            >{{ model.user_full_name }}({{ model.user_id }})</span
          >
        </div>
        <div>
          <span class="body-2">ACTION : </span
          ><span class="body-1 font-weight-bold">{{ model.action }}D</span>
        </div>
        <div>
          <span class="body-2">OBJECT TYPE : </span
          ><span class="body-1 font-weight-bold">{{ model.object_type }}</span>
        </div>
        <div>
          <span class="body-2">OBJECT ID : </span
          ><span class="body-1 font-weight-bold">{{ model.object_id }}</span>
        </div>
        <div>
          <span class="body-2">DATE : </span
          ><span class="body-1 font-weight-bold">{{
            model.activity_date
          }}</span>
        </div>
        <o-table
          ref="differences"
          type="array"
          :data="differences"
          :headers="[
            {
              text: 'NAME',
              align: 'start',
              sortable: false,
              value: 'name',
              type: 'text',
            },
            {
              text: 'OLD',
              align: 'start',
              sortable: false,
              value: 'old_value',
              type: 'text',
            },
            {
              text: '',
              align: 'center',
              sortable: false,
              value: 'icon',
              type: 'slot',
            },
            {
              text: 'NEW',
              align: 'start',
              sortable: false,
              value: 'new_value',
              type: 'text',
            },
          ]"
        >
          <template slot="slot">
            <v-icon small class="mr-2">
              mdi-arrow-right-bold
            </v-icon>
          </template>
        </o-table>
      </o-drawer>
      <v-card-text class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(450)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/execute?time=${new Date().getTime()}`,
            payload: {
              topic: '',
              name: 'check_activity_logs',
              params: {
                a01: parameters.acc_id,
                a02: parameters.usr_id,
                a03: parameters.obj_type,
                a04: parameters.obj_id,
                cnt: parameters.cnt,
                starts: parameters.starts,
              },
            },
          }"
          :headers="[
            {
              text: 'ID',
              align: 'end',
              sortable: false,
              value: 'id',
              type: 'int',
            },
            {
              text: 'DATE',
              align: 'start',
              sortable: false,
              value: 'activity_date',
              type: 'date-time',
            },
            {
              text: 'ACCOUNT',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'ACTION',
              align: 'start',
              sortable: false,
              value: 'action_name',
              type: 'slot',
            },
            {
              text: 'USER',
              align: 'start',
              sortable: false,
              value: 'user_full_name',
              type: 'slot',
            },
            {
              text: 'OBJECT TYPE',
              align: 'start',
              sortable: false,
              value: 'object_type',
              type: 'text',
              width: 100,
            },
            {
              text: 'OBJECT ID',
              align: 'end',
              sortable: false,
              value: 'object_id',
              type: 'text',
              width: 100,
            },
            {
              value: 'action',
              type: 'action',
              sortable: false,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'View',
                  icon: 'mdi-view-headline',
                  color: 'success',
                },
              ],
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-refreshed="refreshed"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'user_full_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.user_id > 0">
                {{ row.prop.item.user_full_name }} ({{ row.prop.item.user_id }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'action_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{
                row.prop.item.action_name.replace(
                  /[-_\/[\]{}()*+?,\\^$|#\s]/g,
                  " "
                )
              }}
            </div>
          </template>
        </o-table>
      </v-card-text>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ManageActivityLog",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Activity Logs",
  },
  data() {
    return {
      title: "Activity Logs",
      busy: false,
      showFilter: false,
      action: "",
      activity_log_data: [],
      accounts: [],
      users: [],
      valid: false,
      parameters: {
        acc_id: 0,
        usr_id: 0,
        obj_type: "",
        obj_id: 0,
        cnt: 200,
        starts: 0,
      },
      baseModel: {
        id: 0,
        account_id: 0,
        action: "",
        activity_date: null,
        details: {},
        differences: {},
        log_id: 0,
        object_id: 0,
        object_type: "-",
        user_id: 0,
      },
      originalModel: {},
      model: {},
      permissions: [],
      permissionData: [],
      differences: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  async mounted() {
     this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
    this.users = await this.getList(
      ["id, concat(last_name, ', ', first_name) as name"],
      "users",
      [],
      [],
      "name"
    );
   /* this.getData(`rest/accounts`, null).then((response) => {
      this.accounts.push({ id: 0, name: "All" });
      response.data.forEach((d) => {
        this.accounts.push({ id: d.id, name: d.account_name });
      });
      this.getData(`rest/users`, null).then((response) => {
        this.users.push({ id: 0, name: "All" });
        response.data.forEach((d) => {
          this.users.push({
            id: d.id,
            name: `${d.last_name}, ${d.first_name}`,
          });
        });*/

        if (this.$route.query.acc_id && this.$route.query.acc_id > 0) {
          this.parameters.acc_id = this.$route.query.acc_id;
        }

        if (this.$route.query.usr_id && this.$route.query.usr_id > 0) {
          this.parameters.usr_id = this.$route.query.usr_id;
        }

        if (this.$route.query.obj_type && this.$route.query.obj_type != "") {
          this.parameters.obj_type = this.$route.query.obj_type;
        }

        if (this.$route.query.obj_id && this.$route.query.obj_id > 0) {
          this.parameters.obj_id = parseInt(this.$route.query.obj_id);
        }

        if (this.$route.query.cnt && this.$route.query.cnt > 0) {
          this.parameters.cnt = parseInt(this.$route.query.cnt);
        }
        if (this.$route.query.starts && this.$route.query.starts > 0) {
          this.parameters.starts = parseInt(this.$route.query.starts);
        }
        // this.reset()
     // });
   // });
  },
  methods: {
    edit(item) {
      this.model = item;
      (this.model.differences = this.model.differences || []),
        (this.differences = Object.keys(this.model.differences)
          .map((d) => {
            return {
              name: d,
              icon: "",
              new_value: this.model.differences[d].new_value || "",
              old_value: this.model.differences[d].old_value || "",
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1)));

      this.action = "edit";
    },
    refreshed(item) {
      this.parameters.starts = item.paging.starts;
      this.parameters.cnt = item.paging.cnt;

      this.changeQueryString(this.parameters);
    },
    search() {},
    reset() {
      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      this.originalModel = _.cloneDeep(this.baseModel);

      this.changeQueryString(this.parameters);

      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.editor {
  max-height: calc(100vh - 289px);
  overflow-y: auto;
}

.json-container {
  max-height: calc(100vh - 425px);
  overflow: scroll;
}
</style>
