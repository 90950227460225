<template>
  <ManageActivityLog dialog-name="Activity Log" />
</template>
<script>
import ManageActivityLog from '@/views/app/manage/ActivityLog'

export default {
  name: 'ActivityLogWrapper',
  components: {
    ManageActivityLog
  }
}
</script>
